import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandler } from "../helpers/apiHandler";

export const getUnifiedInboxAll = createAsyncThunk("aiSales/getUnifiedInboxAll", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/getUnifiedInbox",
    method: "post",
    body,
  });

  return res;
});

export const downloadEmailAnalytics = createAsyncThunk("aiSales/downloadEmailAnalytics", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,

    url: "/aiSales/downloadEmailAnalytics",
    method: "post",
    body,
    downloadFileName: `${body?.nameOfExportFile}.xlsx`,
  });

  return res;
});

export const updateEmailLim = createAsyncThunk("aiSales/updateEmailLim", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/updateEmailLim",
    method: "post",
    body,
  });

  return res;
});

export const patchEmailSettings = createAsyncThunk("aiSales/patchEmailSettings", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/patchEmailSettings",
    method: "post",
    body,
  });

  return res;
});

export const deleteEmailConn = createAsyncThunk("aiSales/deleteEmailConn", async (body: any, { getState }: { getState: any }) => {
  console.log(body, "deleteEmailConn");
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/remEmail",
    method: "post",
    body,
  });
  console.log(res, "deleteEmailConn2");
  return res;
});

export const addEmailBe = createAsyncThunk("aiSales/addEmailBe", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/addMultipleEmail",
    method: "post",
    body,
  });

  return res;
});

export const addSMTP = createAsyncThunk("aiSales/addSMTP", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/addSMTP",
    method: "post",
    body,
    longTimer: true,
  });

  return res;
});

export const testIMAP = createAsyncThunk("aiSales/testIMAP", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/testSMTP",
    method: "post",
    body,
    longTimer: true,
  });

  return res;
});

export const testSMTP = createAsyncThunk("aiSales/testSMTP", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/testSMTP",
    method: "post",
    body,
  });

  return res;
});
// for now just for bnetween sends
export const updateEmailSettings = createAsyncThunk("aiSales/updateEmailSettings", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/updateEmailSettings",
    method: "post",
    body,
  });

  return res;
});
