/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { createSlice, createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { initiateAndGetAllUserInfo } from "../user/asyncRequests";
import {
  addEmailBe,
  addSMTP,
  deleteEmailConn,
  getUnifiedInboxAll,
  patchEmailSettings,
  testIMAP,
  testSMTP,
  updateEmailLim,
  updateEmailSettings,
} from "./asyncRequests";
// import { checkIfProspIsGenerating, doNotSell, downloadProspects, getProspects, searchForAvailProsp, searchProsp } from './asyncRequests';

const outreachEmailsSlice = createSlice({
  name: "outreachEmails",
  initialState: {
    loading: false,
    pendingAddSMTP: false,
    addedSMTP: false,
    additionalEmails: [],
    unsub_sentence: "",
    business_addr: "",
    additionalEmailsTimezone: "",
    additionalEmailsSched: {},
    successfullUpdateMsg: false as boolean | string,
    infoUpdateMsg: false as boolean | string,
    failedUpdateMsg: false as boolean | string,

    // analytics to display
    analyticsBarAll: [] as any,
    analyticsBarCampaign: { campId: null, analytics: [] },

    //download analytics request
    nameOfExportFile: "Meetz: Email Outreach Analytics",
    informationToDownload: ["Email Sends", "Email Opens", "Email Link Clicks", "Likely Forwarded", "Email Replies", "Booked Meetings"],
    campaignsToDownload: "all" as string | string[],
    unixdates: null as null | number[],

    // data of analytics
    dataAll: { campaignId: "all" },
    dataCamp: { campaignId: "all" }, // initializer so it doesnt load
  },
  reducers: {
    deleteOptimisticEmail: (state, action) => {
      const emailsData = _.cloneDeep(state.additionalEmails);

      state.additionalEmails = emailsData?.filter((item: any) => item.id !== action.payload);
    },
    resetAddedSMTP: (state) => {
      state.addedSMTP = false;
    },
    setExportInformationToDownload: (state, action) => {
      state.informationToDownload = action.payload;
    },
    setExportName: (state, action) => {
      state.nameOfExportFile = action.payload;
    },
    setExportCampsId: (state, action) => {
      state.campaignsToDownload = action.payload;
    },
    setExportUnixRange: (state, action) => {
      state.unixdates = action.payload;
    },
    updateOptimisticEmails: (state, action) => {
      const elem = action.payload;
      const emailsArr = state.additionalEmails;
      let emailsArrCopy = _.cloneDeep(emailsArr);
      elem?.forEach((elem) => {
        const { value, id } = elem;

        const propNameToUpd = Object.keys(value)[0];
        const propValToUpd = value[propNameToUpd];
        emailsArrCopy = emailsArrCopy?.map((em) => {
          if (em.id === id) {
            return { ...em, [propNameToUpd]: propValToUpd };
          }
          return em;
        });
      });
      state.additionalEmails = emailsArrCopy;
    },
    // updateEmailSendingTimes: (state,action) => {
    //   const {timeBetweenSends,emailId} = action.payload;
    //   const emailsArr = state.additionalEmails;
    //   const emailsArrCopy = _.cloneDeep(emailsArr);
    //   state.additionalEmails = emailsArrCopy?.map((em)=> {
    //     if(em.id===emailId){
    //    return   {...em, sends_per_day}
    //     }
    //     return em
    //   })
    // },
    resetOutcome: (state) => {
      state.successfullUpdateMsg = false;
      state.failedUpdateMsg = false;
      state.infoUpdateMsg = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {
        state.additionalEmailsSched = action.payload?.aiSettings?.email_schedule || {};
        state.unsub_sentence = action.payload?.aiSettings?.unsub_sentence || "{{startlink}} Unsubscribe {{endlink}} here";
        state.business_addr = action.payload?.aiSettings?.business_addr || "";
        state.additionalEmailsTimezone = action.payload?.aiSettings?.email_timezone?.trim()?.length
          ? action.payload?.aiSettings?.email_timezone?.trim()
          : "America/New_York";
        // console.log( action.payload.sendingEmails,' action.payload.sendingEmails');
        state.additionalEmails = action.payload.sendingEmails || [];
        state.dataAll = action.payload.emailFirstPageData || { campaignId: "all" };
        state.analyticsBarAll = action.payload.emailAnalyticsBar || [
          { id: "email_sent", title: "Total Sends", value: 0, explanation: `Total campaign email sends` },
          { id: "email_opened", title: "Total Opens", value: 0, explanation: `Total number of prospect email opens, excluding your own` },
          { id: "email_clicked", title: "Total Clicks", value: 0, explanation: `Total clicks on links in your email` },
          { id: "likely_forwarded", title: "Likely Forwarded", value: 0, explanation: `Unique contacts that likely forwarded your email` },
          { id: "email_replied", title: "Total Replies", value: 0, explanation: `Total email replies` },
          { id: "meeting_booked", title: "Booked Meetings", value: 0, explanation: `Total booked meetings with Meetz links` },
        ];
      })
      // .addCase(initiateAndGetAllUserInfo.rejected, (state, action) => {
      //   state.analyticsBarAll = [
      //     { id: "sends", title: "Total Sends", value: 0, explanation: `Total campaign email sends` },
      //     { id: "opens", title: "Total Opens", value: 0, explanation: `Total number of prospect email opens, excluding your own` },
      //     { id: "clicks", title: "Total Clicks", value: 0, explanation: `Total clicks on links in your email` },
      //     { id: "forwarded", title: "Likely Forwarded", value: 0, explanation: `Unique contacts that likely forwarded your email` },
      //     { id: "replies", title: "Total Replies", value: 0, explanation: `Total email replies` },
      //     { id: "booked", title: "Booked Meetings", value: 0, explanation: `Total booked meetings with Meetz links` },
      //   ];
      // })
      .addCase(patchEmailSettings.fulfilled, (state, action) => {
        state.additionalEmailsSched = action.payload?.aiSettings?.aiSettings?.email_schedule || {};
        state.additionalEmailsTimezone = action.payload?.aiSettings?.email_timezone?.trim()?.length
          ? action.payload?.aiSettings?.email_timezone?.trim()
          : "America/New_York";
        state.unsub_sentence = action.payload?.aiSettings?.aiSettings?.unsub_sentence || "{{startlink}} Unsubscribe {{endlink}} here";
        state.business_addr = action.payload?.aiSettings?.aiSettings?.business_addr || "";
        state.successfullUpdateMsg = "Updated email settings";
      })
      .addCase(patchEmailSettings.rejected, (state, action) => {
        state.failedUpdateMsg = "Failed to update, please check your internet.";
      })
      .addCase(getUnifiedInboxAll.pending, (state, action) => {
        state.dataAll = {};
      })
      .addCase(getUnifiedInboxAll.fulfilled, (state, action) => {
        state.dataAll = action.payload || { campaignId: "all" };
      })
      .addCase(getUnifiedInboxAll.rejected, (state, action) => {
        state.failedUpdateMsg = `Couldn't fetch prospects, please check your internet`;
        state.dataAll = { campaignId: "all" };
      })
      .addCase(updateEmailLim.fulfilled, (state, action) => {
        if (action.payload?.emailFailUpd) {
          state.failedUpdateMsg = "Failed to update email limit. Please check your internet.";
          state.additionalEmails = action.payload?.emailFailUpd;
        } else {
          state.successfullUpdateMsg = "Updated the sending limits";
        }
      })
      .addCase(updateEmailLim.rejected, (state, action) => {
        console.log(action, "updatedemaillim12");
        state.failedUpdateMsg = "Failed to update email limit. Please check your internet.";
      })
      .addCase(updateEmailSettings.fulfilled, (state, action) => {
        if (action.payload?.emailFailUpd) {
          state.failedUpdateMsg = "Failed to update emails. Please check your internet.";
          state.additionalEmails = action.payload?.emailFailUpd;
        } else {
          state.successfullUpdateMsg = "Updated emails";
        }
      })
      .addCase(updateEmailSettings.rejected, (state, action) => {
        state.failedUpdateMsg = "Failed to update emails. Please check your internet.";
      })
      .addCase(testSMTP.fulfilled, (state, action) => {
        state.pendingAddSMTP = false;
        state.successfullUpdateMsg = "SMTP connection is successful!";
      })
      .addCase(testSMTP.pending, (state, action) => {
        state.pendingAddSMTP = true;
      })
      .addCase(testSMTP.rejected, (state, action) => {
        state.pendingAddSMTP = false;
        state.failedUpdateMsg = "SMTP connection failed, please check the details again";
      })
      .addCase(testIMAP.fulfilled, (state, action) => {
        state.pendingAddSMTP = false;
        state.successfullUpdateMsg = "IMAP connection is successful!";
      })
      .addCase(testIMAP.pending, (state, action) => {
        state.pendingAddSMTP = true;
      })
      .addCase(testIMAP.rejected, (state, action) => {
        state.pendingAddSMTP = false;
        state.failedUpdateMsg = "IMAP connection failed, please check the details again";
      })
      .addCase(addEmailBe.fulfilled, (state, action) => {
        state.additionalEmails = action.payload?.emailsToSendWith;
        state.successfullUpdateMsg = "Email connected";
      })

      .addCase(addEmailBe.rejected, (state, action) => {
        state.failedUpdateMsg = "Email connection failed, please check your internet";
      })
      .addCase(addSMTP.fulfilled, (state, action) => {
        state.addedSMTP = true;
        console.log(action.payload, "emailsToSendWith");
        state.additionalEmails = action.payload?.emailsToSendWith;
        state.successfullUpdateMsg = "SMTP/IMAP connection added successfully!";
        state.pendingAddSMTP = false;
      })
      .addCase(addSMTP.pending, (state, action) => {
        state.pendingAddSMTP = true;
      })
      .addCase(addSMTP.rejected, (state, action) => {
        state.pendingAddSMTP = false;
        state.failedUpdateMsg = "SMTP/IMAP connection failed, please check the details again";
      })
      // .addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {})
      // .addCase(addSMTP.pending, (state, action) => {})

      .addCase(deleteEmailConn.fulfilled, (state, action) => {
        if (action.payload?.emailsToSendWith?.length) {
          state.additionalEmails = action.payload?.emailsToSendWith;
          state.failedUpdateMsg = "Email couldn't be removed";
          return;
        }
        state.successfullUpdateMsg = "Email Removed";
      })
      .addCase(deleteEmailConn.rejected, (state, action) => {
        state.failedUpdateMsg = "Email couldn't be removed. Please check your internet";
      });
  },
});

const { actions, reducer } = outreachEmailsSlice;
export const {
  deleteOptimisticEmail,
  updateOptimisticEmails,
  resetOutcome,
  resetAddedSMTP,
  setExportInformationToDownload,
  setExportName,
  setExportCampsId,
  setExportUnixRange,
} = actions;

export default reducer;

export const outreachEmailsSelector = createSelector(
  (state) => state.outreachEmails,
  (outreachEmails) => outreachEmails
);
