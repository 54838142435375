import { Button, Col, Empty, Flex, Image, Row, Space, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useMemo, useState } from "react";
import { EmailDetailsModal, EmailsStatus } from "src/components/pages/emails-data";
import NoData from "src/shared/images/noData.png";
import { StatusTag, Table, TableHeader, TextEditor } from "src/components/ui";
import { App } from "src/types";
import { emailsData } from "../../info";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import tz from "moment-timezone";
import { outreachEmailsSelector } from "src/store/outreach-emails";
import { useNavigate } from "react-router";
import { fullUserSelector } from "src/store/user";
import { getUnifiedInboxAll } from "src/store/outreach-emails/asyncRequests";
import { AppDispatch } from "src/store";
import { DownloadEmailAnalytics } from "src/components/pages/analytics-components/DownloadEmailAnalytics";

interface EmailsTableProps {
  isTourStep?: boolean;
  campaignId: "all" | string;
  activeId: string;
}

export const EmailsTable: FC<EmailsTableProps> = ({ isTourStep, campaignId, activeId }) => {
  const navigate = useNavigate();
  const [selectedContact, setSelectedContact] = useState<App.EmailsEntity | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [tourStepData, setTourStepData] = useState(emailsData);
  const [actCampIds, setActCampIds] = useState([]);
  const [tableData, setTableData] = useState(emailsData);
  const [openDownloadAnaltrics, setOpenDownloadAnaltrics] = useState(false);
  const memoizedTourStepData = useMemo(() => tourStepData, [tourStepData]);

  const { sdBarCollapsed } = useSelector(fullUserSelector);

  const { dataAll, dataCamp } = useSelector(outreachEmailsSelector);
  console.log(dataAll, "dataAll");

  useEffect(() => {
    setTourStepData(emailsData);
  }, [emailsData]);

  useEffect(() => {
    const actCamps = campaignId === "all" ? dataAll?.campainIdsActive : dataCamp?.campainIdsActive;
    setActCampIds(actCamps || []);
  }, [dataAll, dataCamp]);

  const showModal = () => {
    setOpenModal(true);
  };

  const onCancel = () => {
    setOpenModal(false);
  };
  const formatUnixTimestamp = (unixTimestamp) => {
    const date = tz.unix(unixTimestamp).tz(tz.tz.guess());
    return date.format("MMMM Do 'YY [at] h:mm A");
  };
  console.log(activeId, "activeId");
  const columns: ColumnsType<App.EmailsEntity> = [
    {
      title: "Contact name",
      // className: "emails-contact-name",
      render: (record) => {
        console.log(record, "RECORD");
        return `${record.first_name} ${record.last_name}`;
      },
      // <Typography.Paragraph
      //   onClick={() => {
      //     setSelectedContact(record);
      //     showModal();
      //   }}
      // >{`${record.first_name} ${record.last_name}`}</Typography.Paragraph>
      dataIndex: "prospect",
      align: "left",
      width: "140px",
    },
    {
      title: activeId !== "email_replied" ? "Email Sent" : "Email Replied",
      dataIndex: "analyticToShow",
      render: (record) => {
        // force null or write deleted campaign if not in platform anymore
        const fixCampName =
          !record?.campaign_id || actCampIds?.includes(record?.campaign_id) ? record?.campaign_name : `${record?.campaign_name} (Deleted campaign)`;

        const actCampId = !!record?.campaign_id && actCampIds?.includes(record?.campaign_id) ? record?.campaign_id : null;

        if (activeId !== "email_replied") {
          const sanitizedHtml = DOMPurify.sanitize(record.meetz_email_body || "No email body to show");

          // Create a temporary div to strip the HTML tags and get plain text
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = sanitizedHtml;
          const plainText = tempDiv.textContent || tempDiv.innerText || "";

          const truncatedText = plainText.substring(0, 300) + "...";
          return (
            <Space direction={"vertical"} key={record.id}>
              <Col>
                <Typography.Paragraph className={"email-title"}>
                  {record.meetz_email_subject === "ReplyTo" ? "" : record.meetz_email_subject}
                </Typography.Paragraph>
                {/* <Typography.Paragraph ellipsis={true} style={{ width: 300 }}>
                {}
              </Typography.Paragraph> */}
                {record?.meetz_email_body && record?.meetz_email_body?.length > 150 ? (
                  <Tooltip
                    placement={"right"}
                    title={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record?.meetz_email_body || "No email body to show") }} />}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.meetz_email_body?.substring(0, 150) + "..." || "No email body to show") }}
                    />
                  </Tooltip>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record?.meetz_email_body || "No email body to show") }} />
                )}
                {/* <TextEditor
                content={record.meetz_email_body || "No email body to show"}
                readOnly
                // isMaxHeight
                // style={{ width: 350 }}
              /> */}
              </Col>
              <Col>
                <Row wrap={false} style={{ gap: 3 }}>
                  <Typography.Paragraph>{`Step ${record.step_number} in`}</Typography.Paragraph>
                  <Typography.Paragraph
                    className={"emails-company-name"}
                    style={{ cursor: actCampId ? "pointer" : "none" }}
                    onClick={() => actCampId && navigate(`/campaign/${btoa(`${actCampId + 12000}`)}`)}
                  >
                    {fixCampName && fixCampName?.length > 25 ? (
                      <Tooltip placement={"right"} title={`Campaign Name: ${fixCampName}`}>
                        {fixCampName?.substring(0, 25) + "..."}
                      </Tooltip>
                    ) : (
                      fixCampName
                    )}
                  </Typography.Paragraph>
                </Row>
              </Col>
            </Space>
          );
        } else {
          // const sanitizedHtml = DOMPurify.sanitize(record.meetz_email_body || "No email body to show");

          // // Create a temporary div to strip the HTML tags and get plain text
          // const tempDiv = document.createElement("div");
          // tempDiv.innerHTML = sanitizedHtml;
          // const plainText = tempDiv.textContent || tempDiv.innerText || "";

          // const truncatedText = plainText.substring(0, 300) + "...";
          return (
            <Space direction={"vertical"} key={record.id}>
              <Col>
                <Typography.Paragraph className={"email-title"}>{record.replied_email_subject}</Typography.Paragraph>
                {/* <Typography.Paragraph ellipsis={true} style={{ width: 300 }}>
                {}
              </Typography.Paragraph> */}
                {record?.replied_email_body && record?.replied_email_body?.length > 150 ? (
                  <Tooltip
                    placement={"right"}
                    title={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record?.replied_email_body || "No email body to show") }} />}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(record.replied_email_body?.substring(0, 150)?.replace(/\n/g, "<br />") + "..." || "No email body to show"),
                      }}
                    />

                    {/* <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.replied_email_body?.substring(0, 150) + "..." || "No email body to show") }}
                    /> */}
                  </Tooltip>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: record.replied_email_body?.replace(/\n/g, "<br />") || "No email body to show" }} />
                )}
                {/* <TextEditor
                content={record.meetz_email_body || "No email body to show"}
                readOnly
                // isMaxHeight
                // style={{ width: 350 }}
              /> */}
              </Col>
              <Col>
                <Row wrap={false} style={{ gap: 3 }}>
                  <Typography.Paragraph>{`Step ${record.step_number} in`}</Typography.Paragraph>
                  <Typography.Paragraph
                    className={"emails-company-name"}
                    style={{ cursor: actCampId ? "pointer" : "none" }}
                    onClick={() => actCampId && navigate(`/campaign/${btoa(`${actCampId + 12000}`)}`)}
                  >
                    {fixCampName && fixCampName?.length > 25 ? (
                      <Tooltip placement={"right"} title={`Campaign Name: ${fixCampName}`}>
                        {fixCampName?.substring(0, 25) + "..."}
                      </Tooltip>
                    ) : (
                      fixCampName
                    )}
                  </Typography.Paragraph>
                </Row>
              </Col>
            </Space>
          );
        }
      },
      key: "contents",
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, value) => {
        console.log(value, "value213");
        return (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            {/* {value.map((item: App.EmailStatus, index: number) => ( */}
            <EmailsStatus status={value.fixedFrontEndAnalytic} count={value.count} style={{ margin: 0 }} />
            {value?.analyticToShow?.is_ai_step ? (
              <>
                <StatusTag color={"blue"} value={"Ai Personalized Step"} style={{ margin: 0, marginTop: "10px" }} />{" "}
                {value?.analyticToShow?.used_skip_ai_defemail ? (
                  <Tooltip
                    // style={{ zIndex: 100 }}
                    placement={"right"}
                    title={`Default was used since you ran out of Ai Personalized Email credits when this email was sent out`}
                  >
                    <div>
                      {" "}
                      <StatusTag color={"volcano"} value={"Default Used"} style={{ margin: 0, marginTop: "10px" }} />
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            ) : (
              <StatusTag color={"green"} value={"Templated Email"} style={{ margin: 0, marginTop: "10px" }} />
            )}
            {/* ))} */}
          </div>
        );
      },
      key: "status",
      align: "center",
    },
    {
      title: activeId === "email_replied" ? "Date responded" : activeId === "email_sent" ? "Date Sent" : "Date Most Recently Happened",
      dataIndex: "maxTime",
      key: "dateSent",
      align: "center",
      render: (value) => {
        console.log(activeId, "efiawhbasdf");
        return formatUnixTimestamp(value);
      },
    },
  ];

  const dataToUse = campaignId === "all" ? dataAll : dataCamp;

  const [page, setPage] = useState(1);

  const dispatch = useDispatch<AppDispatch>();

  console.log(dataToUse, "dataToUse", page, dataToUse.availPages, dataToUse.totalAmnt);
  const handleChangePage = (pg) => {
    dispatch(
      getUnifiedInboxAll({
        analyticToGet: activeId,
        page: pg,
        // pageSize,
        // dateRange,
        campaignId,
      })
    );
    setPage(pg);
  };

  useEffect(() => {
    setPage(1);
  }, [activeId]);

  return (
    <React.Fragment>
      <Flex>
        <Typography.Title level={3}>Unified Inbox</Typography.Title>
        <Button className={"link"} style={{ marginLeft: "auto" }} onClick={() => setOpenDownloadAnaltrics(true)}>
          Download Analytics
        </Button>
      </Flex>{" "}
      {/* - more features for full flexibility coming out today! */}
      <br />
      {/* <TableHeader searchPlaceholder={"Search Emails..."} /> */}
      {isTourStep ? (
        <Table customClassName={"emails-table"} columns={columns} dataSource={memoizedTourStepData} />
      ) : (
        // make page change in index only if dif than previous results
        <Table
          customClassName={"emails-table"}
          loading={!dataToUse?.campaignId}
          columns={columns}
          dataSource={dataToUse?.prospectsInRowsList || []}
          locale={{
            emptyText: (
              <Empty
                image={<Image preview={false} src={NoData} alt={"No data"} width={173} height={150} />}
                imageStyle={{
                  height: 180, // Ensure the image height is consistent with its actual height
                  marginTop: "30px",
                }}
                description={<span style={{ marginTop: "10px" }}>No matching prospects found for the selected filter criteria.</span>} // Add margin to separate text from the image
              />
            ),
          }}
          divWidth={sdBarCollapsed ? "calc(100vw - 230px)" : "calc(100vw - 400px)"}
          // setCurrentPage={(num) => handleChangePage(num)}
          // totalAmnt={dataToUse}
          pageSize={10}
          totalAmnt={dataToUse.totalAmnt || 0}
          currentPage={page}
          setCurrentPage={handleChangePage}
          totalPages={dataToUse.availPages || 1}
          quickDropdownSkip
          quickSkip
        />
      )}
      {selectedContact && <EmailDetailsModal open={openModal} onCancel={onCancel} selectedContact={selectedContact} />}
      <DownloadEmailAnalytics open={openDownloadAnaltrics} onClose={() => setOpenDownloadAnaltrics(false)} />
    </React.Fragment>
  );
};
