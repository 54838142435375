/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { createSlice, createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { initiateAndGetAllUserInfo } from "../user/asyncRequests";
import {
  addNewCampaignV2,
  addProsToCampaign,
  approveSteps,
  autoSave,
  createCampaignStepsWithAi,
  delCamps,
  delSuppressedList,
  doNotSell,
  editCamps,
  generateOneAiEmail,
  getCampaignDetails,
  getEmailGraphAnalytics,
  getFilteredLeads,
  getFilteredLeadsRst,
  getNewABTests,
  getSuppressedList,
  pauseCampaign,
  resetCampIdDiscEmails,
  setCampPageNum,
  updateCampaignSettings,
  updateCampaignSteps,
  updateTestEmailSettings,
  updateTestEmailSettingsWithEmail,
  uploadLeadFileAndColumnInd,
  uploadSuppressions,
  valuePropUpd,
  verifyEmail,
  viewMoreProsInStep,
} from "./asyncRequests";
import { addEmailBe } from "../outreach-emails/asyncRequests";
// import { checkIfProspIsGenerating, doNotSell, downloadProspects, getProspects, searchForAvailProsp, searchProsp } from './asyncRequests';

const outreachCampaignSlice = createSlice({
  name: "outreachCampaign",
  initialState: {
    itemIdSel: null,
    loading: false,
    loadingGenTest: false,
    loadingLeads: false,
    loadCreateCamp: false,
    loadingUploadPros: false,
    successfullUpdateMsg: false as boolean | string,
    infoUpdateMsg: false as boolean | string,
    failedUpdateMsg: false as boolean | string,
    // prospect page
    leadsTotalCnt: 0,
    filteredAfterProsUploadName: "",
    leadsLeftOut: 0,
    leadsUploaded: 0,
    leads: [],
    leadsIds: [],
    leadsIdSelected: [],
    uplNameOptions: [],
    // first page of ai campaign
    aiGenProps: {},
    // current ai settings set up
    aiSettings: {},
    // not sure what to do yet
    updatedSuccessfully: false,
    outreachCampLoad: false,
    emailAnalyticsGraphData: [], // display of campaigns main page
    loadingGraphAnalytics: false,
    outreachCampaignsList: [], // display of campaigns main page
    allCampsList: [], // list of all camp names with ids to search in copy camp
    outreachCampaignsCnt: null as { key: number; title: string; value: any }[] | null, // display of campaign count dashboard page
    totalCamps: 0,
    page: 1,
    renameIds: [],
    successfullUpdateMsgEmailDashboard: false as boolean | string,
    infoUpdateMsgEmailDashboard: false as boolean | string,
    failedUpdateMsgEmailDashboard: false as boolean | string,

    // campaign looking at now information
    loadingGenAiForPros: false,
    openSettingsModal: false,
    openVariantFromAction: false,
    firstNewCamp: false,
    loadingMorePros: false,
    loadingCampLookingAt: false,
    campLookingAtNowId: null,
    campaignDisplaying: {},
    eachStepsAnalytics: {}, // shows prospects + every step

    // Creating the outreach campaign ourselves
    popularUploadName: "",
    generatedName: "",
    backupEmail: { body: null, subject: null },
    testAiEmail: { body: null, subject: null },
    warnRegenerateSequenceVal: false,
    loadingAddingCampaign: false,
    loadingNewlyGeneratedTemplatedEmails: false,
    newlyGeneratedTemplatedReplace: false,
    newlyGeneratedTemplatedEmails: [],
    newCampId: null,

    draftOutreachSeqAi: [], // this is when it lands from our backend

    successUpdateSuppressSettings: false as boolean | string,
    failedUpdateSuppressSettings: false as boolean | string,
    prospectSuppressListLoading: false,
    prospectSuppressListCnt: 0,
    supprPage: 0,
    prospectSuppressList: null as null | string[], // suppression list
    supprIdSelected: [],

    // after initial front end processing in a format that fits. This is used to save it even if they go elsewhere while building the campaign
    draftProperlyFormatedOutreachSeqAi: [],
    draftProperlyFormatedOutreachSeqScratch: [],
    invalidDraftStepsEditCampaign: [],
    invalidDraftStepsAiGen: [],
    invalidDraftStepsScratch: [],

    // autosave toggler
    autoSaveToggle: false,

    // special req
    doNotSellSuccess: false,
    verifyEmailSuccess: false as any, // no or yay
  },
  reducers: {
    togAutoSaveToggle: (state) => {
      state.autoSaveToggle = !state.autoSaveToggle;
    },
    setSelectedSupprRowKeys: (state, action) => {
      const { added, removed } = action.payload;
      const oldSel = state.supprIdSelected;
      const almostUpdArray = oldSel.filter((id) => !removed.includes(id));

      // yay
      state.supprIdSelected = [...new Set(almostUpdArray.concat(added))];
    },
    resetUpdatedSuccessfully: (state) => {
      state.updatedSuccessfully = false;
    },
    resetEditCampErrors: (state) => {
      state.invalidDraftStepsEditCampaign = [];
    },
    autosaveSequenceSteps: (state, action) => {
      // only issue is that in scratch it wont save the new backup email. but this is minor so it can be ignored for now
      const req = action.payload;
      if (req.from === "ai") {
        state.draftProperlyFormatedOutreachSeqAi = req.formatedSeq;
      }
      if (req.from === "scratch") {
        state.draftProperlyFormatedOutreachSeqScratch = req.formatedSeq;
      }
      // dont use "else"! otherwise it will go in (because finalSeq triggers this too)
    },
    setInvalidSteps: (state, action) => {
      const { copInvalidSteps, from } = action.payload;
      if (from === "ai") {
        state.invalidDraftStepsAiGen = copInvalidSteps;
      } else if (from === "scratch") {
        state.invalidDraftStepsScratch = copInvalidSteps;
      } else {
        state.invalidDraftStepsEditCampaign = copInvalidSteps;
      }
    },
    setOpenVariantFromAction: (state, action) => {
      state.openVariantFromAction = action.payload;
    },
    setitemIdSel: (state, action) => {
      state.itemIdSel = action.payload;
    },
    setOpenCampSettings: (state, action) => {
      state.openSettingsModal = action.payload;
    },
    setCampLookingAtNowId: (state, action) => {
      state.campLookingAtNowId = action.payload;
    },
    resetFirstNewCamp: (state) => {
      state.firstNewCamp = false;
    },
    resetNewCmpId: (state) => {
      state.newCampId = null;
    },
    setRenameIds: (state, action) => {
      state.renameIds = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    resetOutcome: (state) => {
      state.successfullUpdateMsg = false;
      state.failedUpdateMsg = false;
      state.infoUpdateMsg = false;
    },
    resOutcomeEmailDashboard: (state) => {
      state.successfullUpdateMsgEmailDashboard = false;
      state.failedUpdateMsgEmailDashboard = false;
      state.infoUpdateMsgEmailDashboard = false;
    },
    resOutcomeSupress: (state) => {
      // state.successfullUpdateMsgEmailDashboard = false;
      state.failedUpdateSuppressSettings = false;
      state.successUpdateSuppressSettings = false;
    },
    setBackupEmail: (state, action) => {
      if (action.payload.isSubject) {
        state.backupEmail.subject = action.payload.text;
      } else {
        state.backupEmail.body = action.payload.text;
      }
    },
    doneReplacedStepWithNewEmailVariants: (state, action) => {
      state.newlyGeneratedTemplatedEmails = [];
      state.newlyGeneratedTemplatedReplace = false;
    },
    replaceStepWithNewEmailVariants: (state, action) => {
      state.newlyGeneratedTemplatedReplace = true;
    },
    setAiGenProp: (state, action) => {
      state.aiGenProps = action.payload;
    },
    setSelectedRowKeys: (state, action) => {
      const { added, removed } = action.payload;
      const oldSel = state.leadsIdSelected;
      const almostUpdArray = oldSel.filter((id) => !removed.includes(id));

      // yay
      state.leadsIdSelected = [...new Set(almostUpdArray.concat(added))];
    },
    selectAllCurrent: (state, action) => {
      const whatToDo = action.payload as "add" | "rem";
      let uniqueLeadsId = [];
      if (whatToDo === "add") {
        uniqueLeadsId = [...new Set([...state.leadsIdSelected, ...state.leadsIds])];
      } else {
        uniqueLeadsId = [...new Set([...state.leadsIdSelected])].filter((id) => !state.leadsIds.includes(id));
      }

      state.leadsIdSelected = uniqueLeadsId;
      state.successfullUpdateMsg = `${whatToDo === "add" ? "S" : "Des"}elected filtered prospects`;
    },
    resetUplName: (state) => {
      state.filteredAfterProsUploadName = "";
    },
    resetSelProsIds: (state, action) => {
      state.leadsIdSelected = [];
    },
    resetAiGenProp: (state) => {
      state.aiGenProps = {};
    },
    resetUploadedInfo: (state) => {
      state.leadsLeftOut = 0;
      state.leadsUploaded = 0;
    },
    warnRegenerateSequence: (state, action) => {
      state.warnRegenerateSequenceVal = action.payload;
    },
    //  newCampInfoToAdd: (state,action) => {
    //   state.newCampInfoAddToDb= action.payload;
    // },
    // setFilterFormData: (state,action) => {
    // state.filterFormData =action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {
        state.aiSettings = action.payload.aiSettings || {};
        // console.log(action.payload, "leads1");
        state.leads = action.payload?.leads?.filteredLeads || [];
        state.outreachCampaignsList = action.payload?.outreachCampaigns?.campaignList || [];
        state.allCampsList = action.payload?.outreachCampaigns?.allCampsList || [];
        state.outreachCampaignsCnt = [
          {
            title: "Total Campaigns",
            value: action.payload?.outreachCampaigns?.totalCampCount || 0,
            key: 1,
          },
          {
            key: 2,
            title: "Active Campaigns",
            value: action.payload?.outreachCampaigns?.totalActCampCount || 0,
          },
        ];
        state.totalCamps = action.payload?.outreachCampaigns?.totalCampCount || 0;
        state.emailAnalyticsGraphData = action.payload?.emailAnalyticsOverview;

        if (action.payload?.leads?.uplNameOptions?.length) {
          //  the above variable only comes back when a new filter is implemented. total cnt doesnt change in page chagne
          state.leadsIds = action.payload?.leads?.filteredLeadsId || [];
          state.leadsTotalCnt = action.payload?.leads?.filteredLeadsId?.length || 0;
          state.uplNameOptions = action.payload?.leads?.uplNameOptions;
        }
        state.loadingLeads = false;
      })
      .addCase(autoSave.fulfilled, (state, action) => {
        if (action.payload?.aiSettings?.id) {
          state.aiSettings = action.payload.aiSettings;
        }
      })
      .addCase(getEmailGraphAnalytics.fulfilled, (state, action) => {
        state.emailAnalyticsGraphData = action.payload;
        state.loadingGraphAnalytics = false;
      })
      .addCase(getEmailGraphAnalytics.pending, (state, action) => {
        state.loadingGraphAnalytics = true;
      })
      .addCase(getEmailGraphAnalytics.rejected, (state, action) => {
        state.loadingGraphAnalytics = false;
        state.failedUpdateMsg = `Issue with getting your analytics. Please check your internet, or try again.`;
      })
      .addCase(createCampaignStepsWithAi.fulfilled, (state, action) => {
        const { popName, genName } = action.payload?.campaignNameOpt || {};
        console.log(action.payload, "as132df");
        state.loadCreateCamp = false;
        state.aiSettings = action.payload.aiSettings || {};
        state.popularUploadName = popName || "";
        state.generatedName = genName || "";
        // state.uploadNamesArrayInCamp = uploadNamesArray
        state.draftOutreachSeqAi = action.payload.outreachFlow || [];
        state.backupEmail = action.payload.backupEmail || { body: null, subject: null };
      })
      .addCase(createCampaignStepsWithAi.pending, (state, action) => {
        state.draftProperlyFormatedOutreachSeqAi = [];
        state.loadCreateCamp = true;
        state.draftOutreachSeqAi = [];
      })
      .addCase(createCampaignStepsWithAi.rejected, (state, action) => {
        state.loadCreateCamp = false;
        state.failedUpdateMsg = `Something went wrong, check your internet and make sure the fields are filled out.`;
        state.draftOutreachSeqAi = [];
        state.warnRegenerateSequenceVal = "err" as any;
      })
      .addCase(uploadSuppressions.pending, (state, action) => {
        state.prospectSuppressListLoading = true;
      })
      .addCase(uploadSuppressions.rejected, (state, action) => {
        state.prospectSuppressListLoading = false;
        state.failedUpdateSuppressSettings = "Error adding suppression, please check your internet";
        state.prospectSuppressList = [];
        state.prospectSuppressListCnt = 0;
      })
      .addCase(uploadSuppressions.fulfilled, (state, action) => {
        state.prospectSuppressListLoading = false;
        state.supprPage = 1;
        if (action.payload?.amntUpl) {
          state.successUpdateSuppressSettings = `Added ${action.payload?.amntUpl} suppressions and updated your account`;
        } else {
          state.failedUpdateSuppressSettings = `No suppressions added, recheck the URL's format/emails format`;
        }
        state.prospectSuppressList = action.payload?.updSuppr?.prospectSuppressList || [];
        state.supprIdSelected = [];
        state.prospectSuppressListCnt = action.payload?.updSuppr?.prospectSuppressListCnt || 0;
      })
      .addCase(delSuppressedList.pending, (state, action) => {
        state.prospectSuppressListLoading = true;
      })
      .addCase(delSuppressedList.rejected, (state, action) => {
        state.prospectSuppressListLoading = false;
        state.failedUpdateSuppressSettings = "Something went wrong when trying to delete";
        state.prospectSuppressList = [];
        state.prospectSuppressListCnt = 0;
      })
      .addCase(delSuppressedList.fulfilled, (state, action) => {
        state.prospectSuppressListLoading = false;
        state.prospectSuppressList = action.payload?.prospectSuppressList || [];
        state.successUpdateSuppressSettings = `Deleted suppression`;
        state.supprPage = action.payload?.page || 1;

        state.prospectSuppressListCnt = action.payload?.prospectSuppressListCnt || 0;
      })
      .addCase(getSuppressedList.pending, (state, action) => {
        state.prospectSuppressListLoading = true;
      })
      .addCase(getSuppressedList.rejected, (state, action) => {
        state.prospectSuppressListLoading = false;
        state.failedUpdateSuppressSettings = "Couldn't get suppression list, please check your internet";
        state.prospectSuppressList = [];
        state.prospectSuppressListCnt = 0;
      })
      .addCase(getSuppressedList.fulfilled, (state, action) => {
        state.prospectSuppressListLoading = false;
        state.prospectSuppressList = action.payload?.prospectSuppressList || [];
        console.log(action.payload, "action.payload123");
        state.supprPage = action.payload?.page || 1;

        state.prospectSuppressListCnt = action.payload?.prospectSuppressListCnt || 0;
      })
      .addCase(updateCampaignSteps.pending, (state, action) => {
        state.loadingCampLookingAt = true;
      })
      .addCase(updateCampaignSteps.rejected, (state, action) => {
        state.failedUpdateMsg = "Couldn't update campaign, please check your internet";
        state.loadingLeads = false;
      })
      .addCase(updateCampaignSteps.fulfilled, (state, action) => {
        state.loadingCampLookingAt = false;
        state.updatedSuccessfully = true;

        state.successfullUpdateMsg = `Updated campaign`;
        state.invalidDraftStepsEditCampaign = [];
        console.log(action.payload, "campaignactionpaylo");
        state.campaignDisplaying = action.payload?.campaign;
      })
      .addCase(getFilteredLeads.pending, (state, action) => {
        state.leads = [];
        state.loadingLeads = true;
      })
      .addCase(getFilteredLeads.rejected, (state, action) => {
        state.failedUpdateMsg = "Filter's not applied, please check your internet";
        state.loadingLeads = false;
      })
      .addCase(getFilteredLeads.fulfilled, (state, action) => {
        state.leads = action.payload?.leads?.filteredLeads || [];

        if (action.payload?.leads?.uplNameOptions?.length) {
          state.successfullUpdateMsg = `Filters Applied - ${
            !action.payload?.leads?.filteredLeadsId?.length ? `No prospects found` : `${action.payload?.leads?.filteredLeadsId?.length} prospects found`
          }`;
          //  the above variable only comes back when a new filter is implemented. total cnt doesnt change in page chagne
          state.leadsIds = action.payload?.leads?.filteredLeadsId || [];
          state.leadsTotalCnt = action.payload?.leads?.filteredLeadsId?.length || 0;
          state.uplNameOptions = action.payload?.leads?.uplNameOptions;
        }
        state.loadingLeads = false;
      })
      .addCase(getFilteredLeadsRst.pending, (state, action) => {
        state.leadsIdSelected = [];
      })
      .addCase(getFilteredLeadsRst.fulfilled, (state, action) => {
        state.leads = action.payload?.leads?.filteredLeads || [];

        if (action.payload?.leads?.uplNameOptions?.length) {
          state.leadsIds = action.payload?.leads?.filteredLeadsId || [];
          state.leadsTotalCnt = action.payload?.leads?.filteredLeadsId?.length || 0;
          state.uplNameOptions = action.payload?.leads?.uplNameOptions;
        }
        state.loadingLeads = false;
      })
      .addCase(valuePropUpd.fulfilled, (state, action) => {
        const aiSetCopy = _.cloneDeep(state.aiSettings);
        state.aiSettings = { ...aiSetCopy, value_props: action.payload?.valPropList };
        state.successfullUpdateMsg = `Updated value proposition`;
      })
      .addCase(valuePropUpd.rejected, (state, action) => {
        state.failedUpdateMsg = `Couldn't save your value proposition. Please check your internet.`;
      })
      .addCase(uploadLeadFileAndColumnInd.fulfilled, (state, action) => {
        state.loadingUploadPros = false;
        const { res, leads } = action?.payload;
        const leftOut = res?.leftOut;
        const uploaded = res?.uploaded;

        state.leadsLeftOut = leftOut || 0;
        state.leadsUploaded = uploaded || 0;

        if (uploaded) {
          state.leads = leads?.filteredLeads || [];
          state.filteredAfterProsUploadName = res?.uploadName || "All";
          if (action.payload?.leads?.uplNameOptions?.length) {
            state.leadsIds = leads?.filteredLeadsId || [];
            state.leadsIdSelected = leads?.filteredLeadsId || [];
            state.leadsTotalCnt = leads?.filteredLeadsId?.length || 0;
            state.uplNameOptions = action.payload?.leads?.uplNameOptions;
          }
        }
        state.loadingLeads = false;
      })
      .addCase(uploadLeadFileAndColumnInd.rejected, (state, action) => {
        state.loadingUploadPros = false;
        state.leadsLeftOut = 0;
        state.leadsUploaded = 0;
        state.failedUpdateMsg = `Something went wrong when trying to upload. Please check your internet or that your files is valid.`;
      })
      .addCase(addProsToCampaign.pending, (state, action) => {
        // state.loadingUploadPros = true;
      })
      .addCase(addProsToCampaign.rejected, (state, action) => {
        state.failedUpdateMsg = `Error in adding prospects, please check your internet.`;
      })
      .addCase(addProsToCampaign.fulfilled, (state, action) => {
        state.successfullUpdateMsg = `Added prospects to your campaign`;
        state.loadingAddingCampaign = false;
        state.campaignDisplaying = action.payload?.formattedCampaign;
        state.eachStepsAnalytics = action.payload?.formattedCampaign?.stepsAnalytics; // not sure if correct
        state.firstNewCamp = action.payload?.firstNewCamp;
        state.newCampId = action.payload?.newCampId;
        state.leads = action.payload?.leads?.filteredLeads || [];
        state.leadsIdSelected = [];
        state.outreachCampaignsList = action.payload?.outreachCampaigns?.campaignList || [];
        state.allCampsList = action.payload?.outreachCampaigns?.allCampsList || [];
        state.outreachCampaignsCnt = [
          {
            title: "Total Campaigns",
            value: action.payload?.outreachCampaigns?.totalCampCount || 0,
            key: 1,
          },
          { key: 2, title: "Active Campaigns", value: action.payload?.outreachCampaigns?.totalActCampCount || 0 },
        ];
        state.totalCamps = action.payload?.outreachCampaigns?.totalCampCount || 0;
      })
      .addCase(uploadLeadFileAndColumnInd.pending, (state, action) => {
        state.loadingUploadPros = true;
      })
      .addCase(updateTestEmailSettings.rejected, (state, action) => {
        state.failedUpdateMsg = `Error in updating settings, please check your internet.`;
      })
      .addCase(updateTestEmailSettings.fulfilled, (state, action) => {
        state.aiSettings = action.payload.aiSettings;
        state.successfullUpdateMsg = `Updated settings`;
      })
      .addCase(updateTestEmailSettingsWithEmail.pending, (state, action) => {
        state.loadingGenTest = true;
      })
      .addCase(updateTestEmailSettingsWithEmail.rejected, (state, action) => {
        state.loadingGenTest = false;
        state.failedUpdateMsg = `Error in generating test email, please check your internet.`;
      })
      .addCase(updateTestEmailSettingsWithEmail.fulfilled, (state, action) => {
        state.loadingGenTest = false;
        state.aiSettings = action.payload.aiSettings;
        state.testAiEmail = action.payload.emailTest || { body: null, subject: null };
        state.successfullUpdateMsg = `Generated "Ai Personalized Email" Test`;
      })
      .addCase(getNewABTests.pending, (state, action) => {
        state.loadingNewlyGeneratedTemplatedEmails = true;
        state.newlyGeneratedTemplatedEmails = [];
      })
      .addCase(getNewABTests.rejected, (state, action) => {
        state.loadingNewlyGeneratedTemplatedEmails = false;
        state.failedUpdateMsg = `Error in generating new emails, please check your internet.`;
      })
      .addCase(getNewABTests.fulfilled, (state, action) => {
        state.loadingNewlyGeneratedTemplatedEmails = false;
        state.newlyGeneratedTemplatedEmails = action.payload.newlyGeneratedTemplatedEmails || [];
        state.successfullUpdateMsg = `Generated A/B Testing Emails`;
      })
      .addCase(setCampPageNum.fulfilled, (state, action) => {
        state.outreachCampaignsList = action.payload?.campaignList || [];
        state.outreachCampaignsCnt = [
          {
            title: "Total Campaigns",
            value: action.payload?.totalCampCount || 0,
            key: 1,
          },
          { key: 2, title: "Active Campaigns", value: action.payload?.totalActCampCount || 0 },
        ];
        state.totalCamps = action.payload?.totalCampCount || 0;
        state.outreachCampLoad = false;
      })
      .addCase(setCampPageNum.pending, (state, action) => {
        // state.outreachCampaignsList = []
        state.outreachCampLoad = true;
      })
      .addCase(setCampPageNum.rejected, (state, action) => {
        state.outreachCampLoad = false;
        state.failedUpdateMsg = `Could not fetch campaigns, please check internet or refresh browser.`;
      })
      .addCase(addEmailBe.pending, (state, action) => {
        state.outreachCampLoad = true;
      })
      .addCase(addEmailBe.fulfilled, (state, action) => {
        state.outreachCampLoad = false;
        state.page = 1;
        state.outreachCampaignsList = action.payload?.campList?.campaignList || [];
        state.allCampsList = action.payload?.campList?.allCampsList || [];
        state.outreachCampaignsCnt = [
          {
            title: "Total Campaigns",
            value: action.payload?.campList?.totalCampCount || 0,
            key: 1,
          },
          { key: 2, title: "Active Campaigns", value: action.payload?.campList?.totalActCampCount || 0 },
        ];
        state.totalCamps = action.payload?.campList?.totalCampCount || 0;
      })
      .addCase(addEmailBe.rejected, (state, action) => {
        state.outreachCampLoad = false;
      })
      .addCase(pauseCampaign.fulfilled, (state, action) => {
        state.successfullUpdateMsg = `Paused Campaign`;
        state.outreachCampLoad = false;
        state.page = 1;
        state.outreachCampaignsList = action.payload?.campList?.campaignList || [];
        state.allCampsList = action.payload?.campList?.allCampsList || [];

        state.totalCamps = action.payload?.campList?.totalCampCount || 0;
      })
      .addCase(pauseCampaign.pending, (state, action) => {
        state.outreachCampLoad = true;
      })
      .addCase(pauseCampaign.rejected, (state, action) => {
        state.outreachCampLoad = false;
        state.failedUpdateMsg = `Could not pause campaign, please check internet or refresh browser.`;
      })
      .addCase(resetCampIdDiscEmails.fulfilled, (state, action) => {
        state.successfullUpdateMsg = `Reassigned prospects sending email for disconnected email addresses`;
        state.outreachCampLoad = false;
        state.page = 1;
        state.outreachCampaignsList = action.payload?.campList?.campaignList || [];
        state.allCampsList = action.payload?.campList?.allCampsList || [];

        state.totalCamps = action.payload?.campList?.totalCampCount || 0;
      })
      .addCase(resetCampIdDiscEmails.pending, (state, action) => {
        state.outreachCampLoad = true;
      })
      .addCase(resetCampIdDiscEmails.rejected, (state, action) => {
        state.outreachCampLoad = false;
        state.failedUpdateMsg = `Could not reassign emails in campaigns, please check internet or refresh browser.`;
      })
      .addCase(addNewCampaignV2.fulfilled, (state, action) => {
        state.loadingAddingCampaign = false;
        state.invalidDraftStepsEditCampaign = [];
        state.campaignDisplaying = action.payload?.formattedCampaign;
        state.eachStepsAnalytics = action.payload?.formattedCampaign?.stepsAnalytics; // not sure if correct
        state.firstNewCamp = action.payload?.firstNewCamp;
        state.newCampId = action.payload?.newCampId;
        state.leads = action.payload?.leads?.filteredLeads || [];
        state.leadsIdSelected = [];
        state.outreachCampaignsList = action.payload?.outreachCampaigns?.campaignList || [];
        state.allCampsList = action.payload?.outreachCampaigns?.allCampsList || [];

        state.outreachCampaignsCnt = [
          {
            title: "Total Campaigns",
            value: action.payload?.outreachCampaigns?.totalCampCount || 0,
            key: 1,
          },
          { key: 2, title: "Active Campaigns", value: action.payload?.outreachCampaigns?.totalActCampCount || 0 },
        ];
        state.totalCamps = action.payload?.outreachCampaigns?.totalCampCount || 0;
        if (action.payload?.leads?.uplNameOptions?.length) {
          //  the above variable only comes back when a new filter is implemented. total cnt doesnt change in page chagne
          state.leadsIds = action.payload?.leads?.filteredLeadsId || [];
          state.leadsTotalCnt = action.payload?.leads?.filteredLeadsId?.length || 0;
          state.uplNameOptions = action.payload?.leads?.uplNameOptions;
        } // this is to update the leads after tehy are uploaded
      })
      .addCase(addNewCampaignV2.rejected, (state, action) => {
        state.loadingAddingCampaign = false;
        state.failedUpdateMsg = `Error in creating new campaign, please check your internet and retry.`;
      })
      .addCase(addNewCampaignV2.pending, (state, action) => {
        state.loadingAddingCampaign = true;
        state.itemIdSel = null;
      })

      .addCase(editCamps.fulfilled, (state, action) => {
        // state.page =action.payload.page
        state.outreachCampaignsList = action.payload?.campList?.campaignList || [];
        state.allCampsList = action.payload?.outreachCampaigns?.allCampsList || [];
        state.totalCamps = action.payload?.campList?.totalCampCount || 0;
        state.successfullUpdateMsg = `Updated Campaign Name`;
      })
      .addCase(editCamps.rejected, (state, action) => {
        state.failedUpdateMsg = `Error in renaming campaign, please check your internet or refresh browser.`;
      })

      .addCase(delCamps.fulfilled, (state, action) => {
        state.outreachCampLoad = false;
        state.page = action.payload.page;
        state.outreachCampaignsList = action.payload?.campList?.campaignList || [];
        state.allCampsList = action.payload?.campList?.allCampsList || [];
        state.outreachCampaignsCnt = [
          {
            title: "Total Campaigns",
            value: action.payload?.campList?.totalCampCount || 0,
            key: 1,
          },
          { key: 2, title: "Active Campaigns", value: action.payload?.campList?.totalActCampCount || 0 },
        ];
        state.totalCamps = action.payload?.campList?.totalCampCount || 0;
        state.successfullUpdateMsg = `Deleted Campaign`;
      })
      .addCase(delCamps.pending, (state, action) => {
        state.outreachCampLoad = true;
      })
      .addCase(delCamps.rejected, (state, action) => {
        state.outreachCampLoad = false;
        state.failedUpdateMsg = `Error in deleting campaign, please check your internet or refresh browser.`;
      })
      .addCase(approveSteps.fulfilled, (state, action) => {
        state.successfullUpdateMsg = `Approved step`;
        state.loadingMorePros = false;
        state.campaignDisplaying = action.payload;
        state.eachStepsAnalytics = action.payload?.stepsAnalytics;
      })
      .addCase(approveSteps.pending, (state, action) => {
        state.openSettingsModal = false;
        state.loadingMorePros = true;
        // state.itemIdSel=null
      })
      .addCase(approveSteps.rejected, (state, action) => {
        state.loadingMorePros = false;
        state.failedUpdateMsg = `Failed to approve steps, check your internet and then refresh the browser.`;
      })
      .addCase(viewMoreProsInStep.fulfilled, (state, action) => {
        state.loadingMorePros = false;
        // state.campaignDisplaying=action.payload

        state.eachStepsAnalytics = action.payload?.stepsAnalytics;
      })
      .addCase(viewMoreProsInStep.pending, (state, action) => {
        state.openSettingsModal = false;
        state.loadingMorePros = true;
        // state.itemIdSel=null
      })
      .addCase(viewMoreProsInStep.rejected, (state, action) => {
        state.loadingMorePros = false;
        state.failedUpdateMsg = `Failed to fetch more prospects, check your internet and then refresh the browser.`;
      })
      .addCase(getCampaignDetails.fulfilled, (state, action) => {
        state.loadingCampLookingAt = false;
        state.invalidDraftStepsEditCampaign = [];
        state.campaignDisplaying = action.payload;
        state.eachStepsAnalytics = action.payload?.stepsAnalytics;
      })
      .addCase(getCampaignDetails.pending, (state, action) => {
        // state.campaignDisplaying = {};
        state.openSettingsModal = false;
        state.loadingCampLookingAt = true;
        state.itemIdSel = null;
        state.leadsIdSelected = [];
      })
      .addCase(getCampaignDetails.rejected, (state, action) => {
        state.loadingCampLookingAt = false;
        state.failedUpdateMsg = `Failed to fetch campaign, check your internet and then refresh the browser.`;
      })
      .addCase(generateOneAiEmail.fulfilled, (state, action) => {
        // state.campaignDisplaying=action.payload
        // state.eachStepsAnalytics = action.payload?.stepsAnalytics
        state.loadingGenAiForPros = false;
        state.successfullUpdateMsg = `Generated Ai Email`;

        const eachStepsAnalytics = state.eachStepsAnalytics;
        const prospect = action.payload;
        let found = false;

        console.log(prospect, "prospectprospect");
        for (const key in eachStepsAnalytics) {
          if (eachStepsAnalytics[key] && eachStepsAnalytics[key]?.firstTenProsReady?.length > 0) {
            for (let i = 0; i < eachStepsAnalytics[key].firstTenProsReady.length; i++) {
              const pros = eachStepsAnalytics[key].firstTenProsReady[i];
              if (pros.id === prospect.id) {
                eachStepsAnalytics[key].firstTenProsReady[i] = prospect;
                found = true;
                break;
              }
            }
          }
          if (found) {
            break;
          }
        }
        state.eachStepsAnalytics = eachStepsAnalytics;
      })
      .addCase(generateOneAiEmail.pending, (state, action) => {
        state.loadingGenAiForPros = true;
      })
      .addCase(generateOneAiEmail.rejected, (state, action) => {
        state.loadingGenAiForPros = false;
        state.failedUpdateMsg = `Failed to generate email, you might not have enough credits to generate more Ai Emails.`;
      })
      .addCase(updateCampaignSettings.fulfilled, (state, action) => {
        state.successfullUpdateMsg = `Successfully Updated Campaign`;
        state.page = 1;
        state.outreachCampaignsList = action.payload?.campList?.campaignList || [];
        state.allCampsList = action.payload?.campList?.allCampsList || [];
        state.outreachCampaignsCnt = [
          {
            title: "Total Campaigns",
            value: action.payload?.campList?.totalCampCount || 0,
            key: 1,
          },
          { key: 2, title: "Active Campaigns", value: action.payload?.campList?.totalActCampCount || 0 },
        ];
        state.totalCamps = action.payload?.campList?.totalCampCount || 0;
      })
      .addCase(updateCampaignSettings.rejected, (state, action) => {
        state.failedUpdateMsg = `Not able to update your campaigns settings, please check your internet.`;
      })
      .addCase(doNotSell.fulfilled, (state, action) => {
        state.doNotSellSuccess = true;
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.verifyEmailSuccess = "yay";
      })
      .addCase(verifyEmail.rejected, (state) => {
        state.verifyEmailSuccess = "no";
      });
    //  .addCase(getFilterProspects.pending, (state) => {
    //     if (!state.loading) {
    //       state.loading = true;
    //     }
    //     state.totalProspectsAvailable=0
    //     state.curProspPages=1
    //     state.totalProspPages=1
    //      state.prospOnCurPage=[]
    //   })
  },
});

const { actions, reducer } = outreachCampaignSlice;
export const {
  doneReplacedStepWithNewEmailVariants,
  replaceStepWithNewEmailVariants,
  setSelectedRowKeys,
  resetSelProsIds,
  resetOutcome,
  setAiGenProp,
  resetUploadedInfo,
  resetUplName,
  selectAllCurrent,
  warnRegenerateSequence,
  setBackupEmail,
  resetNewCmpId,
  setPage,
  setRenameIds,
  setCampLookingAtNowId,
  resetFirstNewCamp,
  setOpenCampSettings,
  setitemIdSel,
  setOpenVariantFromAction,
  togAutoSaveToggle,
  autosaveSequenceSteps,
  setInvalidSteps,
  resOutcomeEmailDashboard,
  resetEditCampErrors,
  resetUpdatedSuccessfully,
  resOutcomeSupress,
  setSelectedSupprRowKeys,
} = actions;

export default reducer;

export const outreachCampaignSelector = createSelector(
  (state) => state.outreachCampaign,
  (outreachCampaign) => outreachCampaign
);
