/* eslint-disable react/jsx-fragments */
import { InboxOutlined } from "@ant-design/icons";
import type { GetProp, UploadProps } from "antd";
import { Button, Checkbox, Flex, Upload, message, Typography, Input, Tooltip } from "antd";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import React, { FC, useEffect, useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import { ConfirmButtons, Modal } from "src/components/ui";
import { UploadingFormModal } from "./ui/UploadingFormModal";
import { AddMembersManuallyModal } from "../../../../../../../../team-members/ui/AddMembersManually";
import { SvgIcon } from "src/shared/icons";
import { useDispatch } from "react-redux";
import { uploadLeadFileAndColumnInd } from "src/store/outreach-campaigns/asyncRequests";

const { Dragger } = Upload;

type ExtractionFileModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  setProspectAmountExtracting: (any) => void;
};

interface CsvData {
  title: string;
  info: string[];
}

const filtOptions = [
  {
    value: "remove_prev_upload",
    label: "Remove previously uploaded prospects",
  },
  {
    value: "remove_prev_contacted",
    label: "Remove prospects already contacted",
  },
];

export const initialOptions = [
  {
    value: "email",
    label: "Email",
    column: null,
  },
  {
    value: "first_name",
    label: "First Name",
    column: null,
  },
  {
    value: "last_name",
    label: "Last Name",
    column: null,
  },
  {
    value: "linkedin_profile_url",
    label: "LinkedIn URL",
    column: null,
  },
  {
    value: "title",
    label: "Job Title",
    column: null,
  },
  {
    value: "business_number",
    label: "Company Phone Number",
    column: null,
  },
  {
    value: "direct_phone",
    label: "Direct Phone Number",
    column: null,
  },
  {
    value: "mobile_phone",
    label: "Mobile Phone Number",
    column: null,
  },
  {
    value: "company_name",
    label: "Company Name",
    column: null,
  },
  {
    value: "company_url",
    label: "Company Website URL",
    column: null,
  },
];
export const UploadProspectsModal: FC<ExtractionFileModalProps> = ({ open, onCancel, setProspectAmountExtracting }) => {
  const [checkedFilters, setCheckedFilters] = useState(["remove_prev_upload", "remove_prev_contacted"]);

  const dispatch = useDispatch();
  const [options, setOptions] = useState(initialOptions);
  const [fileData, setFileData] = useState<CsvData[]>([]);
  const [fileName, setFileName] = useState("");
  const [prospectAmount, setProspectAmount] = useState(0);
  const [openUploadFormModal, setOpenUploadFormModal] = useState(false);
  const [prospectNameValue, setProspectNameValue] = useState("");
  const [isAddMembersManuallyModalOpened, setIsAddMembersManuallyModalOpened] = useState(false);
  const closeUploadFormModal = () => {
    setOpenUploadFormModal(false);
  };

  const closeModal = () => {
    onCancel();
  };

  const onInputChange = (value: string) => {
    setProspectNameValue(value);
  };

  const showAddMembersManuallyModal = () => {
    setIsAddMembersManuallyModalOpened(true);
  };

  const closeAddMembersManuallyModal = () => {
    setIsAddMembersManuallyModalOpened(false);
  };

  const handleSubmit = () => {
    setProspectAmountExtracting(prospectAmount);
    const cleanedRows = [];
    fileData.forEach((lead) => {
      const leadInfo = { upload_name: prospectNameValue };
      options.forEach((o) => {
        if (o.value && lead?.length > o.column) {
          leadInfo[o.value] = lead[o.column];
        }
      });
      cleanedRows.push(leadInfo);
    });

    const removeContactedPros = checkedFilters?.includes("remove_prev_contacted");
    const removeLeadsAlreadyUpl = checkedFilters?.includes("remove_prev_upload");

    dispatch(uploadLeadFileAndColumnInd({ cleanedRows, options, removeContactedPros, removeLeadsAlreadyUpl, totalLeads: prospectAmount }));
    // setOpenUploadFormModal(true)
    onCancel();
  };

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues) => {
    setCheckedFilters(checkedValues);
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: ".csv,.xls,.xlsx",
    beforeUpload(file, fileList) {
      const loadingMessageKey = "loadingMessage";
      const timeoutId = setTimeout(() => {
        message.info({
          content: "Large files can take longer to process, especially if your browser has many tabs open. Please wait",
          key: loadingMessageKey,
          duration: 10,
        });
      }, 10000);
      // console.log(file);
      try {
        // console.log(file,'filelist');
        setFileName(file?.name || "");
        if (fileList?.length > 1) {
          message.error("Please upload only one file at a time.");
          return false;
        }
        message.info({ content: "Reading file", key: loadingMessageKey, duration: 20 });

        const reader = new FileReader();
        reader.onload = (event) => {
          if (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            ExcelRenderer(file, (err, resp) => {
              if (err) {
                clearTimeout(timeoutId);
                message.destroy();
                message.error(`Upload failed, try opening the file to check if it's valid`);
                return true;
              } else {
                setFileData(resp?.rows);
              }
            });
          } else if (file.type === "text/csv") {
            // Handle CSV files
            Papa.parse(file, {
              download: true,
              header: false,
              complete: (results) => {
                setFileData(results?.data);
              },
              error: (error) => {
                clearTimeout(timeoutId);
                message.destroy();
                message.error(`Upload failed, try opening the file to check if it's valid`);
                console.error("Error parsing CSV:", error);
                return true;
              },
            });
          } else {
            clearTimeout(timeoutId);
            message.destroy();
            message.error(`Upload failed, try opening the file to check if it's valid`);
            console.error("Error parsing this");
            return true;
          }
          setOpenUploadFormModal(true);

          clearTimeout(timeoutId);
        };
        reader.readAsText(file);

        setTimeout(() => {
          // to have the notification a bit longer
          message.destroy();
        }, 2000);
        return false;
      } catch (e) {
        console.log(e, "error in file upload");
        clearTimeout(timeoutId);
        message.destroy();
        message.error(`Upload failed, try opening the file to check if it's valid`);
        return true;
      }
    },
    onChange(info) {
      const { status, response } = info.file;

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(e) {
      setFileData([]);
      setFileName("");
      setProspectAmount(0);
    },
    onDrop(e) {
      setProspectAmount(0);
    },
    style: { width: 485 },
  };

  useEffect(() => {
    if (fileName?.trim()?.length) {
      setProspectNameValue(fileName?.trim()?.replace(/\.(xlsx|xls|csv)$/, ""));
    }
  }, [fileData]);

  return (
    <>
      {isAddMembersManuallyModalOpened && <AddMembersManuallyModal isAddContact onClose={closeAddMembersManuallyModal} />}
      <Modal width={550} open={open} title={"Upload file with your prospects"} onCancel={closeModal}>
        <UploadingFormModal
          options={options}
          setOptions={setOptions}
          open={openUploadFormModal}
          onCancel={closeUploadFormModal}
          fileData={fileData}
          fileName={fileName}
          setProspectAmount={setProspectAmount}
        />
        <Flex vertical>
          <Flex vertical align={"flex-start"}>
            <Dragger {...props}>
              <p className={"ant-upload-drag-icon"}>
                <InboxOutlined />
              </p>
              <p className={"ant-upload-text"}>Click or drag file to this area to upload</p>
              <p className={"ant-upload-hint"}>Supports bulk prospect upload from any Excel file (XLS/XLSX) or CSV file.</p>
            </Dragger>
            {!!fileName?.length && (
              <>
                <Button className={"link"} onClick={() => setOpenUploadFormModal(true)} style={{ marginTop: "2px" }}>
                  Edit Columns{" "}
                </Button>{" "}
                <div style={{ color: "black", marginTop: "-5px" }}>
                  {prospectAmount || "No"} prospects in file{" "}
                  <Tooltip placement={"right"} title={"Only prospects with valid emails are counted."}>
                    <SvgIcon type={"tooltip"} style={{ marginLeft: 8 }} />
                  </Tooltip>
                </div>{" "}
              </>
            )}
            {/* <Typography.Title level={4} style={{ margin: "28px 0" }}> TODOf
            Or 
          </Typography.Title>
          <Typography.Title level={4} style={{ marginTop: 0, marginBottom:8 }}>
            Manually Add Prospects
          </Typography.Title> */}

            {/* <Button className={"link"} onClick={showAddMembersManuallyModal}>Add</Button> WTF is this for? prob dont need it */}
            <Typography.Title level={4} style={{ marginTop: 28, marginBottom: 8 }}>
              Name of prospect list
            </Typography.Title>
            <Input placeholder={"Example: VP_1-50HC"} value={prospectNameValue} onChange={(e) => onInputChange(e.target.value)} required />
          </Flex>
          <Typography.Title level={4} style={{ marginTop: 28, marginBottom: 8 }}>
            Filter
          </Typography.Title>
          <Checkbox.Group style={{ width: "100%", margin: "5px 0 28px 0" }} onChange={onChange} value={checkedFilters}>
            <Flex vertical gap={10}>
              {filtOptions.map((item) => (
                <Checkbox key={item.value} value={item.value}>
                  {item.label}
                </Checkbox>
              ))}
            </Flex>
          </Checkbox.Group>
          <ConfirmButtons
            onCancel={closeModal}
            leftButtonTitle={"Back"}
            rightButtonTitle={"Upload prospects"}
            disabled={!prospectAmount || !prospectNameValue?.length}
            handleSubmit={handleSubmit}
            disabledExplanation={!prospectAmount ? "Add prospects - Make sure to mark the email column" : "Add file name"}
          />{" "}
          <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginTop: 8 }}>
            *By Uploading You Agree to the T&C Policies.
          </Typography.Paragraph>
        </Flex>
      </Modal>
    </>
  );
};
